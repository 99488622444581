<template>
  <div class="sm:c-col-4 md:c-col-11 lg:c-col-10">
    <div v-if="loading">
      <loading-page title="Validando acesso..." />
    </div>
    <div
      v-else
      class="c-row"
    >
      <div class="c-col-full md:c-col ui-min-w-0 md:c-d-flex md:ui-flex-col">
        <!-- Título -->
        <div class="c-text-subtitle c-text-gray-400">
          Conta ativada com sucesso
        </div>

        <!-- Mensagem -->
        <div class="c-text-b2 c-text-gray-300 ui-mb-4 md:ui-flex-grow">
          Faça login para prosseguir na web ou no app.
        </div>

        <!-- Botão -->
        <div class="ui-w-full ui-mt-4">
          <router-link :to="{ name: 'login', query: getQuery() }">
            <c-button
              data-cy="go-to-login-button"
              size="md"
              full
            >
              Ir para login
            </c-button>
          </router-link>
        </div>
      </div>

      <!-- Ilustração -->
      <div
        id="image-wrapper"
        class="c-col-full md:c-col-4 ui-min-w-0 ui-mx-4 c-d-flex c-vertical-end c-horizontal-center md:c-horizontal-start"
      >
        <img
          class="ui-mt-4 ui--mb-8"
          src="@/assets/images/email-confirmed-illustration.svg"
          alt="Imagem de email confirmado"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';
import LoadingPage from '@/components/loading-page';
import { delayInSeconds } from '@/utils/async';
import { invalidUserIdAndTokenCombinationError } from '@/utils/errors';

export default {
  components: {
    CButton,
    LoadingPage,
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    try {
      const query = this.$route.query;
      await Promise.all([
        delayInSeconds(2),
        this.$services.accounts.confirmEmail(query.id, query.token),
      ]);
      this.$services.events.notifyEmailConfirmationSuccess();
    } catch (e) {
      this.goToLogin();

      // o erro pode ter sido por algum dos query params (id+token) estarem errados,
      // mas também pode ser que o usuário já tenha ativado a conta anteriormente,
      // e nesse caso, a combinação id+token não será mais encontrada no Accounts.
      // qualquer coisa diferente disso, será registrado no sentry.
      if (e !== invalidUserIdAndTokenCombinationError) {
        throw e;
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    goToLogin() {
      this.$router.push({
        name: 'login',
        query: this.getQuery(),
      });
    },
    getQuery() {
      return {
        ...this.$route.query,
        id: undefined,
        token: undefined,
      };
    },
  },
};
</script>

<style scoped lang="scss">
/* medidas do figma */
#image-wrapper > img {
  width: 232px;
}

@include c-mq(md) {
  #image-wrapper > img {
    width: 275px;
    min-width: 275px; /* não deixa a coluna encolher a imagem */
  }
}

/* não deixa a coluna ficar maior que a imagem */
@include c-mq(md) {
  #image-wrapper {
    max-width: 275px;
  }
}
</style>
