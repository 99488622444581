<template>
  <stepper
    v-if="showStepper"
    :steps="checkoutSteps"
    :current-id="currentStepId"
  />
</template>

<script>
import Stepper from '@/components/stepper/index';
import { checkoutSteps } from '@/consts';

export default {
  name: 'PStepper',
  components: { Stepper },
  data: () => ({
    checkoutSteps,
    currentStepId: null,
  }),
  computed: {
    showStepper() {
      return this.$route.query.type === 'checkout' && !!this.currentStepId;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.currentStepId = route.meta.stepperId;
      },
    },
  },
};
</script>
