<template>
  <router-link :to="{ name: 'meus-dados' }">
    <img
      id="estrategia-logo"
      class="logo"
      :src="logo"
      :style="{ filter: fillColor }"
      alt="Estratégia"
    >
  </router-link>
</template>

<script>
/*
map das cores em Filter
https://stackoverflow.com/a/53336754/6188402
https://codepen.io/sosuke/pen/Pjoqqp
*/
const logoFillColors = {
  white: 'invert(100%) sepia(1%) saturate(2306%) hue-rotate(304deg) brightness(120%) contrast(100%)',
  blue: 'invert(58%) sepia(12%) saturate(5399%) hue-rotate(189deg) brightness(104%) contrast(102%)',
};

export default {
  name: 'EstrategiaLogo',
  props: {
    color: {
      default: 'white',
      type: String,
      validator: (value) => value in logoFillColors,
    },
    iconPath: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      logoUrl: this.$image.get('estrategia-logo'),
    };
  },

  computed: {
    fillColor() {
      return this.iconPath ? '' : logoFillColors[this.color];
    },
    logo() {
      return this.iconPath || this.logoUrl;
    },
  },
};
</script>

<style lang="scss">
#estrategia-logo {
  min-width: 152px;
  min-height: 48px;
  max-width: 266px;
  max-height: 84px;
  object-fit: contain
}

</style>
