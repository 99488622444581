<template>
  <div
    class="c-bg-primary-200 bg ui-h-full"
    :style="{ backgroundImage: `url(${bgUrl})` }"
  >
    <!-- Coruja -->
    <div class="ui-absolute ui-h-full ui-w-full">
      <div class="c-container ui-h-full c-d-flex c-center">
        <div class="c-row">
          <div class="md:c-col" />
          <div class="md:c-col-4">
            <div
              class="owl"
              :style="{ backgroundImage: `url(${owlUrl})` }"
            />
          </div>
          <div class="md:c-col-1" />
        </div>
      </div>
    </div>

    <div class="c-container ui-pt-8 h-header">
      <!-- Logo e stepper -->
      <div class="c-row">
        <!-- Logo Estratégia -->
        <div class="c-col-2 sm:c-col-2 md:c-col-2 ui-mb-4">
          <estrategia-logo />
        </div>

        <!-- Stepper -->
        <div class="c-col-2 sm:c-col ui-mb-4">
          <p-stepper />
        </div>
      </div>
    </div>

    <div class="c-container page-content">
      <div class="c-row c-center ui-h-full">
        <div class="c-col-2 sm:c-col-6 md:c-col-10 lg:c-col-8 c-d-flex ui-justify-center">
          <span class="c-text-h1 ui-text-center c-text-white ui-z-30">{{ title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PStepper from '@/components/p-stepper';
import EstrategiaLogo from '@/components/estrategia-logo';

export default {
  name: 'LoadingPage',
  components: {
    EstrategiaLogo,
    PStepper,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      owlUrl: this.$image.get('owl'),
      bgUrl: this.$image.get('loading-page-bg'),
    };
  },
};
</script>

<style scoped>
.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

@media (min-width: 768px) {
  .bg {
    background-size: cover;
  }
}

.owl {
  width: 384px;
  height: 243px;
  max-width: 60vw;
  background-repeat: no-repeat;
  background-size: contain;
}

.page-content {
  margin-top: unset;
  height: calc(100% - var(--header-height));
}

.h-header {
  height: var(--header-height);
}
</style>
