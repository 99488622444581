<template>
  <div class="ui-flex ui-flex-row">
    <div
      v-for="(step, index) of steps"
      :key="step.name"
      class="step ui-flex ui-flex-col"
      :class="{ 'step-gt': index > currentIndex }"
      :style="{ width: `calc(100% / ${steps.length})` }"
    >
      <div class="c-text-white c-text-b2 ui-h-6 ui-self-center">
        <span
          :class="{ 'ui-hidden': index !== currentIndex }"
          class="sm:ui-flex ui-whitespace-no-wrap"
        >
          {{ step.name }}
        </span>
      </div>
      <div class="ui-flex ui-flex-row ui-w-full ui-self-center">
        <div
          class="ui--mr-2 ui-flex-1 ui-h-1 ui-self-center"
          :class="index === 0 ? '' : index > currentIndex ? 'light-bar' : 'c-bg-primary-400'"
        />
        <div class="ball-shadow ui-w-6 ui-h-6 ui-rounded-full c-bg-primary-400 ui-self-center" />
        <div class="ball ui-rounded-full ui-w-3 ui-h-3 c-bg-white ui-border-4 ui-center ui-self-center" />
        <div
          class="ui-flex-1 ui-h-1 ui-self-center"
          :class="index === steps.length - 1 ? '' : index >= currentIndex ? 'light-bar' : 'c-bg-primary-400'"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentIndex() {
      return this.steps.findIndex(step => step.id === this.currentId);
    },
  },
};
</script>

<style scoped>
.step .ball-shadow {
  opacity: 0.08;
}

.step .ball {
  border-color: var(--color-primary-400);
  margin-left: -18px;
}

.step-gt .ball-shadow {
  opacity: 0;
}

.step-gt .ball {
  border-color: #ccc;
  border-width: 2px;
}

.step .light-bar {
  background-color: white;
  opacity: 0.48;
}
</style>
